// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.L54Fg{margin-bottom:.5rem;margin-left:.2rem;cursor:pointer}.tVZ1C::part(side-modal){width:600px}`, "",{"version":3,"sources":["webpack://./src/app/components/help/Help.module.scss"],"names":[],"mappings":"AAAA,OACE,mBAAA,CACA,iBAAA,CACA,cAAA,CAIA,yBACE,WAAA","sourcesContent":[".infoIcon {\n  margin-bottom: 0.5rem;\n  margin-left: 0.2rem;\n  cursor: pointer;\n}\n\n.modal {\n  &::part(side-modal) {\n    width: 600px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoIcon": `L54Fg`,
	"modal": `tVZ1C`
};
export default ___CSS_LOADER_EXPORT___;
