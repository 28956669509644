// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wXsb8{margin-left:1.8rem;width:100%}.wXsb8 * a{text-decoration:underline !important}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldDescription/FormFieldDescription.module.scss"],"names":[],"mappings":"AAAA,OACE,kBAAA,CACA,UAAA,CACA,WACE,oCAAA","sourcesContent":[".description {\n  margin-left: 1.8rem;\n  width: 100%;\n  & * a {\n    text-decoration: underline !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `wXsb8`
};
export default ___CSS_LOADER_EXPORT___;
