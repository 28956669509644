// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ypTIA::part(file-item),.ypTIA::part(file-list){width:100%}.jGR2g{padding:5px .5rem;margin-bottom:1.5rem}.jGR2g::part(button){color:var(--wpp-button-primary-bg-color, var(--wpp-brand-color));background-color:rgba(0,0,0,0);padding:0;margin:0 !important}.u4N5h{width:100%}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldFileUpload/FormFieldFileUpload.module.scss"],"names":[],"mappings":"AACE,gDAEE,UAAA,CAGJ,OACE,iBAAA,CACA,oBAAA,CACA,qBACE,gEAAA,CACA,8BAAA,CACA,SAAA,CACA,mBAAA,CAGJ,OACE,UAAA","sourcesContent":[".fileUpload {\n  &::part(file-item),\n  &::part(file-list) {\n    width: 100%;\n  }\n}\n.downloadTemplate {\n  padding: 5px 0.5rem;\n  margin-bottom: 1.5rem;\n  &::part(button) {\n    color: var(--wpp-button-primary-bg-color, var(--wpp-brand-color));\n    background-color: transparent;\n    padding: 0;\n    margin: 0 !important;\n  }\n}\n.downloadContainer {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUpload": `ypTIA`,
	"downloadTemplate": `jGR2g`,
	"downloadContainer": `u4N5h`
};
export default ___CSS_LOADER_EXPORT___;
