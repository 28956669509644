// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asl5L{padding-top:.5rem}`, "",{"version":3,"sources":["webpack://./src/app/components/form/formField/formFieldCounter/FormFieldCounter.module.scss"],"names":[],"mappings":"AAAA,OACE,iBAAA","sourcesContent":[".container {\n  padding-top: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `asl5L`
};
export default ___CSS_LOADER_EXPORT___;
